import { useSelector, useDispatch } from 'react-redux';
import { ownshipsIDsSelector, setOwnshipIDs } from 'reducers/liveTrafficSlice';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

const OwnshipCell = ({ params }) => {
	const dispatch = useDispatch();
	const ownshipIDs = useSelector(ownshipsIDsSelector);

	const isOwnship = ownshipIDs.includes(params.id);

	const handleUnpin = () => {
		dispatch(setOwnshipIDs(ownshipIDs.filter(id => id !== params.id)));
	};

	const handlePin = () => {
		dispatch(setOwnshipIDs([...ownshipIDs, params.id]));
	};

	if (params.aircraft_type === 'uav' || params.aircraft_type === 'aircraft') {
		return isOwnship ? (
			<PushPinIcon onClick={handleUnpin} sx={{ cursor: 'pointer' }} />
		) : (
			<PushPinOutlinedIcon onClick={handlePin} sx={{ cursor: 'pointer' }} />
		);
	} else return null;
};

export default OwnshipCell;
