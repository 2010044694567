import { t } from 'i18next';
import { orderBy, startCase, upperCase } from 'lodash-es';
import { browserTimeZone, dateDisplayTimeMinutesSeconds } from 'utils/timeHelpers';
import dayjs from 'utils/customDayJS';
import distance from '@turf/distance';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/material';
import { $danger, $success } from 'constants/styles';
import {
	feetToMeter,
	metersPerSecondToKmPerHour,
	metersPerSecondToMilesPerHour,
	meterToFt
} from 'utils/conversionHelpers';
import {
	getOperatingPictureColumns,
	getOperatingPictureSourceOptions,
	getOperatingPictureStatusOptions,
	getHighAltitudeThresholdFt,
	getMidAltitudeThresholdFt
} from 'components/airSpace/airspaceConstants';
import arrow from 'assets/images/material_nav_arrow.png';
import default_airplane from 'assets/images/default_airplane.png';
import chevron from 'assets/images/material_chevron.png';
import single_engine_airplane from 'assets/images/single_engine_airplane.png';
import jet_airplane from 'assets/images/jet_airplane.png';
import helicopter from 'assets/images/helicopter.png';
import large_airplane from 'assets/images/large_airplane.png';
import OwnshipCell from 'components/airSpace/bottomPanel/OwnshipCell';

import { $highAltitudeColor, $lowAltitudeColor, $midAltitudeColor } from 'constants/styles/_colors';
import formatcoords from 'formatcoords';
import circle from '@turf/circle';
import destination from '@turf/destination';
import center from '@turf/center';
import { lineString } from '@turf/helpers';
import { CURRENT_ACCOUNT_ID } from 'constants/localStorageConstants';

export const getAMAircraftType = type => {
	switch (type) {
		case 'adsb': {
			return { label: t('ADSB'), value: 'adsb' };
		} //Airplane icon
		case 'asterix': {
			return { label: t('Asterix'), value: 'asterix' };
		} //Circle with arrow icon
		case 'uav': {
			return { label: t('UAV'), value: 'uav' };
		} //Drone icon
		case 'aircraft': {
			return { label: t('UAV'), value: 'aircraft' };
		} //Drone icon
		case 'unknown': {
			return { label: t('Unknown'), value: 'unknown' };
		} //Circle with arrow icon
		default: {
			return { label: t('Unknown'), value: 'unknown' };
		} //Circle with arrow icon
	}
};

export const getAMAltitudeDisplay = (row, value, showLabel, units) => {
	const unitLabel = units === 'imperial' ? 'ft' : 'm';

	const icon =
		row.altitude_change > 0 ? (
			<ArrowDropUpIcon fontSize='large' sx={{ color: $success }} />
		) : (
			<ArrowDropDownIcon fontSize='large' sx={{ color: $danger }} />
		);

	const showIcon = value !== 'N/A';
	const showUnitLabel = showLabel && value !== 'N/A';

	let convertedValue = value; //value always comes in as ft
	if (value !== 'N/A') {
		convertedValue = units === 'metric' ? feetToMeter(value) : value;
	}

	return (
		value && (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{convertedValue.toLocaleString()} {showUnitLabel ? unitLabel : ''} {showIcon && icon}
			</Box>
		)
	);
};

export const getAMGroundSpeedDisplay = ({ row, units }) => {
	const aircraftLowercaseLabel = units === 'imperial' ? t('mph') : t('kph');

	if (row.provider === 'aero') {
		const unitOfMeasure = units === 'imperial' ? t('mph') : t('m/s');
		const value =
			units === 'imperial' ? metersPerSecondToMilesPerHour(row.ground_speed) : row.ground_speed;
		return `${value} ${unitOfMeasure}`;
	}
	const isADSB = row.aircraft_type === 'adsb';

	const label = isADSB ? aircraftLowercaseLabel : t('m/s');
	const groundSpeedMPH = metersPerSecondToMilesPerHour(row.ground_speed);
	const groundSpeedKPH = metersPerSecondToKmPerHour(row.ground_speed);

	const aircraftSpeedValue = units === 'imperial' ? groundSpeedMPH : groundSpeedKPH;

	if (isADSB) {
		return `${aircraftSpeedValue} ${label}`;
	} else if (row.ground_speed !== undefined && row.ground_speed !== null) {
		return `${row.ground_speed} ${label}`;
	} else {
		return 'N/A';
	}
};

export const getLiveTrafficTableColumns = ({ isForPreviewProp, trafficUnits }) => {
	const airMeshColumns = [
		{
			field: 'actions',
			type: 'actions',
			headerName: t('Pin'),
			width: 50,
			renderCell: params => <OwnshipCell params={params.row} />
		},
		{
			field: 'timestamp',
			headerName: t('Timestamp'),
			width: 225,
			renderCell: params => {
				return dayjs
					.unix(params.row.timestamp / 1000) //Receiving in microseconds
					.tz(browserTimeZone)
					.format(dateDisplayTimeMinutesSeconds);
			}
		},
		{ field: 'provider_id', headerName: t('Provider ID'), width: 150 },
		{
			field: 'aircraft_type',
			headerName: t('Type'),
			width: 100,
			renderCell: parameters => {
				return getAMAircraftType(parameters.row.aircraft_type)?.label;
			}
		},
		{
			field: 'altitude_agl_ft',
			headerName: trafficUnits === 'imperial' ? t('Altitude AGL (ft)') : t('Altitude AGL (m)'),
			width: 175,
			renderCell: parameters =>
				getAMAltitudeDisplay(parameters.row, parameters.row.altitude_agl_ft, false, trafficUnits)
		},
		{
			field: 'heading',
			headerName: t('Heading'),
			width: 150,
			renderCell: params => {
				return convertDegreesToCardinalDirection(params.row.heading);
			}
		},
		{
			field: 'ground_speed',
			headerName: t('Ground Speed'),
			width: 175,
			renderCell: parameters =>
				getAMGroundSpeedDisplay({ row: parameters.row, units: trafficUnits })
		},
		{ field: 'lat', headerName: t('Latitude'), width: 150 },
		{ field: 'long', headerName: t('Longitude'), width: 150 },
		{
			field: 'altitude_rel_ft',
			headerName: trafficUnits === 'imperial' ? t('Altitude REL (ft)') : t('Altitude REL (m)'),
			width: 175,
			renderCell: parameters =>
				getAMAltitudeDisplay(parameters.row, parameters.row.altitude_rel_ft, false, trafficUnits)
		},
		{
			field: 'altitude_msl_ft',
			headerName: trafficUnits === 'imperial' ? t('Altitude MSL (ft)') : t('Altitude MSL (m)'),
			width: 175,
			renderCell: parameters =>
				getAMAltitudeDisplay(parameters.row, parameters.row.altitude_msl_ft, false, trafficUnits)
		},
		{
			field: 'emergency_state',
			headerName: t('Emergency State'),
			width: 200,
			renderCell: parameters => {
				return parameters.row.emergency_state ? t('None') : t('N/A');
			}
		},
		{
			field: 'signal_source',
			headerName: t('Signal Source'),
			width: 150,
			renderCell: parameters => {
				return parameters.row.signal_source ? parameters.row.signal_source : t('N/A');
			}
		},
		{
			field: 'id',
			width: 350,
			headerName: t('Track ID')
		},
		{
			field: 'provider',
			headerName: t('Provider'),
			width: 200,
			renderCell: parameters => {
				return startCase(parameters.row.provider);
			}
		},
		{
			field: 'rid',
			headerName: t('Operator ID'),
			width: 200
		},
		{
			field: 'sn',
			headerName: t('Serial Number'),
			width: 200
		},
		{
			field: 'operator_lat',
			headerName: t('Takeoff Latitude'),
			width: 150
		},
		{
			field: 'operator_long',
			headerName: t('Takeoff Longitude'),
			width: 150
		}
	];
	return isForPreviewProp ? airMeshColumns.filter(col => !col.hideFromPreview) : airMeshColumns;
};

export const filterTrafficData = (
	data,
	aircraftTypeFilterValue,
	providerFilterValue,
	sourceTypeFilterValue
) => {
	return data.filter(item => {
		const matchesAircraftType =
			aircraftTypeFilterValue.length === 0 || aircraftTypeFilterValue.includes(item.aircraft_type);
		const matchesProvider =
			providerFilterValue.length === 0 || providerFilterValue.includes(item.provider);
		const matchesSourceType =
			sourceTypeFilterValue.length === 0 || sourceTypeFilterValue.includes(item.signal_source);
		return matchesAircraftType && matchesProvider && matchesSourceType;
	});
};

export const filterOperatingPicture = (data, typeFilterValue, sourcesFilterValue) => {
	return data.filter(item => {
		const matchesType =
			typeFilterValue.length === 0 || typeFilterValue.includes(item.properties.type);
		const matchesSource =
			sourcesFilterValue.length === 0 ||
			sourcesFilterValue.includes(item.properties.is_own_account);
		return matchesType && matchesSource;
	});
};

export const formatTrafficDataForTable = (data, mapPinLocation, trafficUnits) => {
	const dataWithDistanceFromPin = data.map(index => {
		return {
			...index,
			distanceFromPin: distance(index.geometry.coordinates, mapPinLocation.geometry.coordinates)
		};
	});

	const orderedData = orderBy(dataWithDistanceFromPin, ['distanceFromPin'], ['asc']);

	const columns = getLiveTrafficTableColumns({ trafficUnits });

	return {
		columns,
		rows: orderedData
	};
};

export const formatOperatingPictureDataForTable = (data, mapPinLocation) => {
	const dataWithDistanceFromPin = data.map(index => {
		//need to validate that index.geometry is a valid geojson feature
		if (!index?.geometry || !index?.geometry?.coordinates) {
			return index;
		}

		//Find center of polygon
		const centerFeature = center(index?.geometry);

		if (!centerFeature?.geometry?.coordinates) {
			return {
				...index,
				distanceFromPin: distance(index.geometry.coordinates, mapPinLocation.geometry.coordinates)
			};
		} else {
			return index;
		}
	});

	const orderedData = orderBy(dataWithDistanceFromPin, ['distanceFromPin'], ['asc']);

	const columns = getOperatingPictureColumns();

	return {
		columns,
		rows: orderedData
	};
};

export const createGeoJsonSource = features => {
	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features
		}
	};
};

export const createLineAlongHeading = (startCoordinates, heading, distanceInMiles) => {
	const options = { units: 'miles' };

	// Calculate the endpoint 2 miles away using the given heading
	const endPoint = destination(startCoordinates, distanceInMiles, heading, options);

	// Create a LineString feature from start to endpoint
	const line = lineString([startCoordinates, endPoint.geometry.coordinates]);

	return line;
};

export const createOwnshipPointFeatureSource = features => {
	//Take the feature's coordinates and add 1 mile along the heading
	const oneMilePointFeatures = features.map(feature => {
		const { geometry, properties } = feature;
		const { coordinates } = geometry;
		const heading = properties.heading;
		const point = destination(coordinates, 1, heading, { units: 'miles' });
		return {
			...point,
			properties: {
				...properties,
				heading: convertDegreesToThreeDigit(heading)
			}
		};
	});

	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: oneMilePointFeatures
		}
	};
};

export const createOwnshipFeaturesSource = features => {
	const twoMilesRadiusFeatures = features.map(feature => {
		const { geometry } = feature;
		const { coordinates } = geometry;
		const circleFeature = circle(coordinates, 2, { units: 'miles' });
		return {
			...circleFeature,
			properties: feature.properties
		};
	});

	const twoMileHeadingLineFeatures = features.map(feature => {
		const { geometry, properties } = feature;
		const { coordinates } = geometry;
		const { heading } = properties;
		const line = createLineAlongHeading(coordinates, heading, 2);
		return {
			...line,
			properties: properties
		};
	});

	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: [...twoMileHeadingLineFeatures, ...twoMilesRadiusFeatures, ...features] //include features so that there is a center point to display text
		}
	};
};

const removeIntFromProvider = string => {
	//Example: removes '_12' from 'airmesh_sdk_12'
	return string.replace(/_\d+/g, '');
};

export const getAltitudeInOrderOfPreference = data => {
	const isValidAltitude = value => value !== null && value !== undefined && value !== 'N/A';

	//Display altitudes in order of preference depending on what is available in the data object:
	//1.AGL, 2. REL, 3.MSL,
	//If all are null, show AGL as 'N/A'

	if (isValidAltitude(data?.altitude_agl_ft)) {
		return {
			value: data.altitude_agl_ft,
			typeLabel: 'AGL',
			type: 'altitude_agl_ft'
		};
	} else if (isValidAltitude(data?.altitude_rel_ft)) {
		return {
			value: data.altitude_rel_ft,
			typeLabel: 'REL',
			type: 'altitude_rel_ft'
		};
	} else if (isValidAltitude(data?.altitude_msl_ft)) {
		return {
			value: data.altitude_msl_ft,
			typeLabel: 'MSL',
			type: 'altitude_msl_ft'
		};
	}
	return {
		value: 'N/A',
		typeLabel: 'AGL',
		type: 'altitude_agl_ft'
	};
};

export const setAltitudeInOrderOfPreference = data => {
	//1.AGL, 2. REL, 3.MSL
	if (data?.altitude_agl_mt) {
		return data.altitude_agl_mt;
	} else if (data?.altitude_rel_mt) {
		return data.altitude_rel_mt;
	} else if (data?.altitude_msl_mt) {
		return data.altitude_msl_mt;
	}
	return null;
};

export const formatAirmeshDataIntoFeatures = data => {
	return data.map(index => {
		const normalizeProperties = {
			index: index,
			timestamp: index.observed_timestamp,
			aircraft_type: index.type,
			id: index.track_id,
			provider: removeIntFromProvider(index.provider),
			provider_id: index.provider_id_human,
			altitude: meterToFt(setAltitudeInOrderOfPreference(index)),
			altitude_agl_ft: index.altitude_agl_mt ? meterToFt(index.altitude_agl_mt) : 'N/A',
			altitude_rel_ft: index.altitude_rel_mt ? meterToFt(index.altitude_rel_mt) : 'N/A',
			altitude_msl_ft: index.altitude_msl_mt ? meterToFt(index.altitude_msl_mt) : 'N/A',
			heading: index.heading_deg,
			ground_speed: index.ground_speed_mps,
			lat: index.geometry.coordinates[1],
			long: index.geometry.coordinates[0],
			adsb_emitter_type: index.adsb_emitter_type,
			emergency_state: index.emergency_state,
			signal_source: index.signal_source,
			rid: index.rid,
			sn: index.sn,
			operator_lat: index?.operator_geometry?.coordinates[1],
			operator_long: index?.operator_geometry?.coordinates[0]
		};

		return {
			type: 'Feature',
			geometry: index.geometry,
			...normalizeProperties,
			properties: normalizeProperties
		};
	});
};

export const convertDegreesToCardinalDirection = degrees => {
	// Normalize degrees to be within the range of 0 to 360
	let normalizedDegrees = ((degrees % 360) + 360) % 360;

	// Define cardinal and intercardinal directions with precise boundaries
	const directions = [
		{ dir: 'N', min: 0, max: 11.25 },
		{ dir: 'NNE', min: 11.25, max: 33.75 },
		{ dir: 'NE', min: 33.75, max: 56.25 },
		{ dir: 'ENE', min: 56.25, max: 78.75 },
		{ dir: 'E', min: 78.75, max: 101.25 },
		{ dir: 'ESE', min: 101.25, max: 123.75 },
		{ dir: 'SE', min: 123.75, max: 146.25 },
		{ dir: 'SSE', min: 146.25, max: 168.75 },
		{ dir: 'S', min: 168.75, max: 191.25 },
		{ dir: 'SSW', min: 191.25, max: 213.75 },
		{ dir: 'SW', min: 213.75, max: 236.25 },
		{ dir: 'WSW', min: 236.25, max: 258.75 },
		{ dir: 'W', min: 258.75, max: 281.25 },
		{ dir: 'WNW', min: 281.25, max: 303.75 },
		{ dir: 'NW', min: 303.75, max: 326.25 },
		{ dir: 'NNW', min: 326.25, max: 348.75 },
		{ dir: 'N', min: 348.75, max: 360 }
	];

	// Find the direction that matches the normalized degrees
	const direction = directions.find(d => normalizedDegrees >= d.min && normalizedDegrees < d.max);
	const directionDisplay = direction ? direction.dir : 'N'; // Default to 'N' if no direction is found

	return `${directionDisplay} (${degrees.toFixed(0)}°)`;
};

export const convertDegreesToThreeDigit = degrees => {
	// Normalize the heading to be within 0-360 degrees
	let normalizedHeading = ((degrees % 360) + 360) % 360;

	// Format the heading as a 3-digit string
	return normalizedHeading.toFixed(0).padStart(3, '0');
};

export const getAltitudeColor = (altitude, units) => {
	if (altitude < getMidAltitudeThresholdFt(units).value) {
		return $lowAltitudeColor; // Use the low altitude color below the mid altitude threshold
	} else if (altitude < getHighAltitudeThresholdFt(units).value) {
		return $midAltitudeColor; // Use the mid altitude color between mid and high altitude thresholds
	} else {
		return $highAltitudeColor; // Use the high altitude color above the high altitude threshold
	}
};

//write a function called getTrafficIcon that return the same icon as what is defined in the icon-image property of the mapbox layer

export const getTrafficIcon = f => {
	let imgSource;
	switch (f.properties.aircraft_type) {
		case 'uav':
		case 'aircraft':
			imgSource = arrow;
			break;
		case 'adsb':
			switch (f.properties.adsb_emitter_type) {
				case 'single_engine':
					imgSource = single_engine_airplane;
					break;
				case 'jet':
					imgSource = jet_airplane;
					break;
				case 'helicopter':
					imgSource = helicopter;
					break;
				case 'large':
					imgSource = large_airplane;
					break;
				default:
					imgSource = default_airplane;
			}
			break;
		case 'asterix':
			imgSource = chevron;
			break;
		default:
			imgSource = default_airplane;
	}
	return imgSource;
};

export const convertCoordinatesFromDecimalToDMS = coordinates => {
	const [longitude, latitude] = coordinates;
	if (latitude && longitude) {
		const formatted = formatcoords(longitude, latitude, true);
		return formatted.format('D:M:s X', { latLonSeparator: ' / ' });
	}
};

export const convertAirMeshAircraftTypesToOptions = aircraftTypes => {
	return aircraftTypes.map(aircraftType => getAMAircraftType(aircraftType));
};

export const convertAirMeshProviderTypesToOptions = providerTypes => {
	return providerTypes.map(providerType => {
		return {
			label: startCase(providerType),
			value: providerType
		};
	});
};

export const convertAirMeshSignalSourcesToOptions = signalSources => {
	return signalSources.map(signalSource => {
		return {
			label: signalSource ? startCase(signalSource) : 'N/A',
			value: signalSource
		};
	});
};

export const createAirMeshTrailsAndProjectionsSource = originalFeatures => {
	const trailFeatures = [];
	const projectionFeatures = [];

	originalFeatures.forEach(originalFeature => {
		const { geometry, properties } = originalFeature;
		const { previous_positions, heading } = properties;

		if (properties.aircraft_type === 'adsb') {
			const fourMileProjection = createLineAlongHeading(geometry.coordinates, heading, 4);

			let coordinates = [];
			if (previous_positions?.length > 0) {
				// Add previous positions first
				previous_positions.forEach(position => {
					coordinates.push(position.geometry.coordinates);
				});
			}
			// Finally add the current position
			coordinates.push(geometry.coordinates);

			const feature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates
				}
			};

			trailFeatures.push(feature);

			projectionFeatures.push(fourMileProjection);
		}
	});

	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: [...trailFeatures, ...projectionFeatures]
		}
	};
};

export const formatOperatingPictureIntoFeatures = data => {
	const currentAccountId = localStorage.getItem(CURRENT_ACCOUNT_ID);
	const features = data.map(obj => ({
		id: obj?.id,
		type: 'Feature',
		geometry: obj?.geom?.coordinates ? obj?.geom : null,
		properties: {
			...obj?.geom?.properties,
			id: obj?.id,
			operators: obj?.operators,
			starts: obj?.starts,
			ends: obj?.ends,
			status: upperCase(obj?.status),
			is_own_account: obj?.account_id?.toString() === currentAccountId,
			type: obj?.type,
			facility: obj?.facility,
			ceiling: obj?.ceiling
		}
	}));
	return features;
};

export const getOperatingPictureStatusFromValue = value => {
	return getOperatingPictureStatusOptions().find(o => o.value === value);
};

export const getOperatingPictureSourceFromValue = value => {
	return getOperatingPictureSourceOptions().find(o => o.value === value);
};
