//Source: https://www.taniarascia.com/websockets-in-redux/

import {
	airMeshAircraftTypesSelector,
	airMeshChannelsSelector,
	airMeshProviderTypesSelector,
	airMeshPusherInstanceSelector,
	pauseTrafficUpdatesSelector,
	airMeshSignalSourceTypesSelector,
	resetAMState,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAirMeshSignalSourceTypes
} from 'reducers/liveTrafficSlice';
import dayjs from 'utils/customDayJS';
import { formatAirmeshDataIntoFeatures } from 'components/airSpace/airspaceUtils';
import Pusher from 'pusher-js';
import {
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY,
	BASE_JWT_URL
} from 'constants/environmentVariables';
import { TOKEN } from 'constants/localStorageConstants';

export const airMeshMiddleware = () => parameters => next => action => {
	const { type } = action;
	const { dispatch, getState } = parameters;
	const airMeshPusherInstance = airMeshPusherInstanceSelector(getState());
	const airMeshChannels = airMeshChannelsSelector(getState());

	const token = localStorage.getItem(TOKEN);

	let inactivityTimeout = null;

	const resetInactivityTimer = dispatch => {
		// Clear any existing timeout
		if (inactivityTimeout) {
			clearTimeout(inactivityTimeout);
		}

		// Set a new 30-second timeout
		inactivityTimeout = setTimeout(() => {
			//Clear all features if there has been no update on any channel for 30 seconds
			//Handles the case where no events are received to trigger the regular pruning of old features
			dispatch(setAMTrafficFeatures([]));
		}, 30000); // 30 seconds
	};

	const handleWebsocketError = () => {
		dispatch(setAMConnectionLoading(false));
		dispatch(setAMWebSocketConnected(false));
		dispatch(setAMConnectionError(true));
		console.error('Failed to connect to Air Mesh');
	};

	const handleWebsocketClosed = () => {
		dispatch(resetAMState());
	};

	const setAircraftTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.aircraft_type);
		const existingTypes = airMeshAircraftTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshAircraftTypes(uniqueTypes));
	};

	const setProviderTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.provider);
		const existingTypes = airMeshProviderTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshProviderTypes(uniqueTypes));
	};

	const setSignalSourceTypes = updatedFeatures => {
		const types = updatedFeatures.map(feature => feature.signal_source).filter(s => s !== null);
		const existingTypes = airMeshSignalSourceTypesSelector(getState());
		const uniqueTypes = [...new Set([...types, ...existingTypes])];
		dispatch(setAirMeshSignalSourceTypes(uniqueTypes));
	};

	const handleApplyUpdates = event => {
		resetInactivityTimer(dispatch);
		const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
		if (!pauseTrafficUpdates) {
			const updatedFeatures = updateFeatures(event.data, getState().liveTraffic.airMeshFeatures);
			dispatch(setAMTrafficFeatures(updatedFeatures));
			setAircraftTypes(updatedFeatures);
			setProviderTypes(updatedFeatures);
			setSignalSourceTypes(updatedFeatures);
		}
	};

	switch (type) {
		case 'airMesh/connect': {
			const newPusherInstance = new Pusher(AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY, {
				cluster: AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
				channelAuthorization: {
					endpoint: `${BASE_JWT_URL}/api/pusher-auth`,
					headers: {
						Authorization: 'Bearer ' + token
					}
				}
			});

			dispatch(setAMPusherInstance(newPusherInstance));

			newPusherInstance.connection.bind('connected', () => {
				dispatch(setAMWebSocketConnected(true));
				dispatch(setAMConnectionError(false));
				dispatch(setAMConnectionLoading(false));
				dispatch(airMeshHandleSubscriptions());
			});

			newPusherInstance.connection.bind('failed', e => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('unavailable', () => {
				handleWebsocketError();
			});

			newPusherInstance.connection.bind('disconnected', () => {
				handleWebsocketClosed();
			});
			break;
		}
		case 'airMesh/handleSubscriptions': {
			const pusherKnownChannels = airMeshPusherInstance?.channels?.channels
				? Object.values(airMeshPusherInstance?.channels?.channels)
				: [];
			const pusherSubscribedChannels = pusherKnownChannels.filter(c => c.subscribed);

			const addedChannelNames = airMeshChannels.filter(
				channel => !pusherSubscribedChannels.map(c => c.name).includes(channel)
			);
			const removedChannels = pusherSubscribedChannels.filter(
				channel => !airMeshChannels.includes(channel.name)
			);

			addedChannelNames.forEach(channelName => {
				const newChannel = airMeshPusherInstance.subscribe(channelName);
				//Listen for track-event data
				newChannel.bind('track-event', event => {
					handleApplyUpdates(event);
				});

				//Listen for events to handle errors
				newChannel.bind('pusher:subscription_error', e => {
					handleWebsocketError(e.error);
				});
			});

			removedChannels.forEach(channel => {
				channel.unsubscribe();
			});

			break;
		}
		case 'airMesh/disconnect': {
			if (airMeshPusherInstance) {
				airMeshPusherInstance.disconnect();
			}
			break;
		}
		default: {
			break;
		}
	}

	return next(action);
};

export const airMeshConnect = payload => ({ type: 'airMesh/connect', payload });
export const airMeshDisconnect = () => ({ type: 'airMesh/disconnect' });
export const airMeshHandleSubscriptions = () => ({ type: 'airMesh/handleSubscriptions' });

export const updateFeatures = (incomingFeatures, currentFeatures) => {
	const normalizedIncomingFeatures = formatAirmeshDataIntoFeatures(incomingFeatures);
	const updatedFeatures = currentFeatures.map(currentFeature => {
		//check if the same feature is in the incoming features, but with new information
		const featureWithNewInformation = normalizedIncomingFeatures.find(
			featureWithNewInformation => featureWithNewInformation.id === currentFeature.id
		);

		const altitudeChange = featureWithNewInformation?.properties?.altitude
			? featureWithNewInformation.properties.altitude - currentFeature.properties.altitude
			: 0;

		if (featureWithNewInformation) {
			const isADSB = featureWithNewInformation.properties.aircraft_type === 'adsb';
			let previousPositions = currentFeature.properties?.previous_positions
				? [...currentFeature.properties.previous_positions]
				: [];
			if (isADSB) {
				//Add an array of features to the current feature, which keeps track of the aircraft's previous positions.
				//Only add to the previous positions array if the incoming feature's position is different that the last previous position

				const lastPreviousPosition = previousPositions[previousPositions.length - 1];

				const previousLong = lastPreviousPosition
					? lastPreviousPosition.geometry.coordinates[0]
					: null;
				const previousLat = lastPreviousPosition
					? lastPreviousPosition.geometry.coordinates[1]
					: null;
				const currentLong = featureWithNewInformation.geometry.coordinates[0];
				const currentLat = featureWithNewInformation.geometry.coordinates[1];
				const isDifferentPosition = previousLong !== currentLong || previousLat !== currentLat;

				if (isDifferentPosition || previousPositions.length === 0) {
					let simplifiedFeatureForTrail = {
						...featureWithNewInformation,
						properties: {
							altitude: featureWithNewInformation.properties.altitude
						}
					};
					previousPositions.push(simplifiedFeatureForTrail);
				}

				//Remove any previous positions that are more than 20 seconds old
				const now = dayjs();
				const timeFrame = now.subtract(20, 'second');
				previousPositions = previousPositions.filter(previousPosition => {
					const previousPositionDate = dayjs(previousPosition.timestamp);
					return previousPositionDate > timeFrame;
				});
			}
			return {
				...featureWithNewInformation,
				altitude_change: altitudeChange,
				properties: {
					...featureWithNewInformation.properties,
					altitude_change: altitudeChange,
					previous_positions: previousPositions
				}
			};
		}
		return currentFeature;
	});

	const now = dayjs();

	const updatedFeaturesOldFeaturesRemoved = updatedFeatures.filter(feature => {
		const featureDate = dayjs(feature.properties.timestamp);
		const isAsterix = feature.properties.aircraft_type === 'asterix';
		const timeFrame = isAsterix ? now.subtract(5, 'second') : now.subtract(30, 'second');
		return featureDate > timeFrame;
	});

	const newFeatures = normalizedIncomingFeatures.filter(
		incomingFeature =>
			!updatedFeaturesOldFeaturesRemoved.find(
				currentFeature => currentFeature.id === incomingFeature.id
			)
	);

	return [...updatedFeaturesOldFeaturesRemoved, ...newFeatures];
};
